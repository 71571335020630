import React from 'react'
import styled from 'styled-components'
import { BoldBlue, Content } from '@components/Landing/Common'
import { Gloria_Hallelujah } from 'next/font/google'
import TryButton from '@components/Landing/TryButton'
import Title from '@components/Landing/Title'
import HeadCTA from '@components/Landing/HeadCTA'
import Box from '@ui/Box'

const Head = styled.section`
  padding: 22px 0;
  text-align: center;

  @media (max-width: 768px) {
    margin-top: 40px;
  }
`
const HeadBgGradient = styled.stop`
  stop-color: #2e3187;
  //animation: gradient 5s linear infinite forwards;

  @keyframes gradient {
    0% {
      stop-color: #2e3187;
      transform: scale(0);
    }

    50% {
      stop-color: #7f519a;
      transform: scale(0.2);
    }

    100% {
      stop-color: #2e3187;
      transform: scale(0);
    }
  }
`

const HeadBgGradient1 = styled.stop`
  stop-color: #108084;
  //animation: gradient1 5s linear infinite forwards;

  @keyframes gradient1 {
    0% {
      stop-color: #108084;
      transform: scale(0);
    }

    50% {
      stop-color: #6090ea;
      transform: scale(0.2);
    }

    100% {
      stop-color: #108084;
      transform: scale(0);
    }
  }
`

const HeadBg = styled.svg`
  position: absolute;
  width: 100%;
`

const Subtitle = styled.h2`
  color: #f2f2f2;
  font-size: 24px;
  font-weight: 400;
  line-height: normal;
  margin: 0;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`

const HeadImg = styled.img`
  max-width: 950px;
  width: 100%;
  height: auto;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 84px;
`

const HeadContent = styled.div``
const HeadWrap = styled.div`
  position: relative;
`

const Compare = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
  margin-top: 70px;

  @media all and (max-width: 600px) {
    flex-direction: column;
  }
`

const CompareImage = styled.img`
  color: #40e0ec;
  font-size: 32px;
  width: 100%;
`

const CompareOld = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  text-align: center;
  margin: 16px 0 0 32px;
  width: 44%;

  @media all and (max-width: 600px) {
    width: 100%;
    margin: 0 0 32px;
  }

  ${CompareImage} {
    margin-top: 38px;

    @media all and (max-width: 600px) {
      margin-top: 0;
    }
  }
`

const CompareNew = styled.div`
  color: #9b9b9b;
  font-size: 14px;
  text-align: center;
  margin: 16px 32px 0 0;
  width: 52%;

  @media all and (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`

const CompareTitle = styled.div`
  color: #40e0ec;
  font-size: 32px;
  margin-bottom: 40px;

  @media all and (max-width: 600px) {
    font-size: 26px;
  }
`

const CompareArrow = styled.img`
  color: #9b9b9b;
  font-size: 14px;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 120px;
  margin: auto;

  @media all and (max-width: 860px) {
    top: 180px;
    width: 100px;
  }

  @media all and (max-width: 600px) {
    display: none;
  }
`

const gloria = Gloria_Hallelujah({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
})

const HeadComponent = () => {
  return (
    <HeadWrap>
      <HeadBg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 641" fill="none">
        <g filter="url(#filter0_f_348_2891)">
          <path
            d="M2 142C2 142 146.975 294.962 252.073 311.173C387.635 332.082 431.958 192.833 568.801 202.222C744.188 214.254 715.454 489.348 891.046 480.805C982.751 476.344 1116.31 282.671 1248.23 311.173C1380.15 339.675 1439 468.393 1439 468.393"
            stroke="url(#paint0_linear_348_2891)"
            strokeWidth="120"
          />
        </g>
        <defs>
          <filter
            id="filter0_f_348_2891"
            x="-141.534"
            y="0.700684"
            width="1735.08"
            height="640.299"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_348_2891" />
          </filter>
          <linearGradient
            id="paint0_linear_348_2891"
            x1="721"
            y1="442.5"
            x2="756.5"
            y2="125.5"
            gradientUnits="userSpaceOnUse"
          >
            <HeadBgGradient />
            <HeadBgGradient1 offset="1" />
          </linearGradient>
        </defs>
      </HeadBg>
      <Content>
        <Head>
          <HeadContent>
            <Title iconLeft="-30px">
              <BoldBlue $shadow>Drive sales</BoldBlue> with our no-code pricing table
            </Title>
            <Subtitle>
              Discover customised modern pricing table designs to match your brand and
              boost clicks with AB testing.
            </Subtitle>
            <Content maxWidth={1200}>
              <Compare>
                <CompareNew>
                  <CompareTitle className={gloria.className}>
                    Get the modern pricing table
                  </CompareTitle>
                  <CompareImage
                    src="/img/landing/compare-new.png"
                    alt="Modern pricing tables"
                  />
                </CompareNew>
                <CompareOld>
                  <CompareTitle className={gloria.className}>
                    instead of the old boring one
                  </CompareTitle>
                  <CompareImage
                    src="/img/landing/compare-old.png"
                    alt="Old pricing tables"
                  />
                </CompareOld>
              </Compare>
            </Content>

            <HeadCTA />
          </HeadContent>
        </Head>
      </Content>
    </HeadWrap>
  )
}

export default HeadComponent
